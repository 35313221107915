import { objectStrAnyType } from "./InterfaceConst";

export const starTransform = [
  "all",
  "one",
  "two",
  "three",
  "four",
  "five",
  "villas & apartment",
];
export const mealText = [
  "No Meal",
  "Breakfast (CP)",
  "Breakfast and dinner (MAP)",
];
export const privateTourFixedValues = [
  "South Goa Tour",
  "North Goa Tour",
  "Both North & South Tour",
];
export const showCalculatorOfLoc = ["6499df325ccd39608e34be6f"];
export const showCalculatorOfLocName = ["goa"];

// Colors
export const constColor = {
  outlinedColor: "#f57c00",
  tabsColor: "#ddf9c1",
  textWhite: "#fff",
  paperColor: "#b1e4e6",
  horizontalBar: "#1d3557",
  calculatorResCard: "#d9ed92",
  pageNoButton: "#161B33",
  logoBlue: "blue",
};
// End Date of the price calculator
export const CALENDDATE = '2024/10/01' // "2024/05/01";;

export const constGradient = {
  gradient1: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  gradient2: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
  gradient3: "linear-gradient(45deg, #66BB6A 30%, #81C784 90%)",
  gradient4: "linear-gradient(45deg, #E63946 30%, #F4A261 90%)",
  gradient5: "linear-gradient(45deg, #457B9D 30%, #1D3557 90%)",
  gradient6: "linear-gradient(45deg, #2A9D8F 30%, #264653 90%)",
  gradient7: "linear-gradient(45deg, #D8BFD8 30%, #6A057F 90%)",
};

export const colorList = [
  "#E63946",
  "#A8DADC",
  "#457B9D",
  "#1D3557",
  "#F4A261",
  "#2A9D8F",
  "#E76F51",
  "#D00000",
  "#006D77",
  "#83C5BE",
  "#E29578",
  "#264653",
  "#E9C46A",
  "#5E60CE",
  "#D8BFD8",
  "#DDA15E",
  "#BC6C25",
  "#6A057F",
  "#AB83A1",
  "#392F5A",
  "#4A4E69",
  "#6D597A",
  "#9D8189",
  "#ACD8AA",
  "#E57373",
  "#6B5B95",
  "#D64161",
  "#FF7D47",
  "#2980B9",
  "#A7226E",
  "#EC2049",
  "#2A4B7C",
  "#B39DDB",
  "#39CCCC",
  "#837D88",
  "#FF9A8B",
  "#80DEEA",
  "#595959",
  "#F26B38",
  "#C9A7EB",
  "#7FDBFF",
];
export const razorPayKeyId = "rzp_live_IxI3LRYD69t6us";

// export const apiURL = "http://localhost:5968";
// export const apiURL = 'https://server-dot-tripyog.el.r.appspot.com'
export const apiURL = "https://api.tripyog.in";

export const messageEncoded: objectStrAnyType = {
  success: "Thank You, Payment Successful",
  failed: "Payment Failed! please try after some time",
  "": "Thank You",
};
